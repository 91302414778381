import { Outlet, Route, Routes } from "react-router-dom";
import axios from "axios";
import "./style.css";
import Menu from "./Menu";
import { useEffect, useState } from "react";
import { message } from "@vavt/message";
import Loader from "./Loader";

const ClikerMain = ({ Root, setUserId, setCanAccessMiniApp }) => {
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getUser = async () => {
    if (window.Telegram) {
      const { WebView } = window.Telegram;
      const userDevice = WebView.initParams.tgWebAppPlatform;
      const initParams = WebView.initParams;
      const tgWebAppData = initParams.tgWebAppData;
      const urlParams = new URLSearchParams(tgWebAppData);
      const userParam = urlParams.get("user");
      const refParam = urlParams.get("start_param");
      const user = JSON.parse(userParam);
      const refId = JSON.parse(refParam);
      const userIdFromUrl = user.id;
      const refid = refId;
      const userName = user.first_name;
      if (userIdFromUrl) {
        setUserId(userIdFromUrl);
        localStorage.setItem('id',userIdFromUrl)
        if (userDevice === "ios" || userDevice === "android") {
          setCanAccessMiniApp(true);
        }
        try {
          const response = await axios.get(`${Root}/user/${userIdFromUrl}`);
          if (response.data) {
            setUserData(response.data);
            setIsLoading(false);
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            const data = {
              userName,
              userId: userIdFromUrl,
              referrerId: refid,
            };
            const register = await axios.post(`${Root}/reg`, data);
            setUserData(register.data.user);
            setIsLoading(false);
          } else {
            message.error("Error in fetching data", { class: "t-info" });
          }
        }
      }
    // } else {
    //   const urlParams = new URLSearchParams(window.location.search);
    //   const userParam = urlParams.get("id");
    //   const name = urlParams.get("n");
    //   const refParam = urlParams.get("ref");
    //   const userIdFromUrl = userParam;
    //   const refid = refParam;
    //   const userName = name;
    //   if (userIdFromUrl) {
    //     setUserId(userIdFromUrl);
    //     const lId = localStorage.getItem("id");
    //     if (lId != userIdFromUrl) {
    //       localStorage.setItem("id", userIdFromUrl);
    //     }
    //     try {
    //       const response = await axios.get(`${Root}/user/${userIdFromUrl}`);
    //       if (response.data) {
    //         setUserData(response.data);
    //         setIsLoading(false);
    //       }
    //     } catch (error) {
    //       if (error.response && error.response.status === 404) {
    //         const data = {
    //           userName,
    //           userId: userIdFromUrl,
    //           referrerId: refid,
    //         };
    //         const register = await axios.post(`${Root}/reg`, data);
    //         setUserData(register.data.user);
    //         setIsLoading(false);
    //       } else {
    //         message.error("Error in fetching data", { class: "t-info" });
    //       }
    //     }
    //   }
    }
  };

  // const registerUser = async () => {
  //   try {
  //     const data = {
  //       userName: "a",
  //       userId: 1233456,
  //     };
  //     const response = await axios.post(`${Root}/reg`, data);
  //     if (response.status === 200) {
  //       setUserData(response.data);
  //     }
  //   } catch (error) {}
  // };

  useEffect(() => {
    getUser();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div className='cliker'>
        <Outlet />
        <Menu />
      </div>
    </>
  );
};

export default ClikerMain;
