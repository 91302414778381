import QRCode from "react-qr-code";
import BEP20 from "../../../assets/BSCLogo.png";
import TRC20 from "../../../assets/TRONLogo.png";
import BNB from "../../../assets/BNB.png";
import TRX from "../../../assets/TRX.png";

import { FaCopy } from "react-icons/fa";
import { Bounce, toast } from "react-toastify";
import { useEffect, useState } from "react";
import useInterval from "use-interval";
import { ethers } from "ethers";
import axios from "axios";

const PageThree = ({
  address,
  getQrCode,
  pageOne,
  amount,
  pageTwo,
  transaction,
  Root,
  changeUserBalance,
  privateToken,
  chain,
  bnbP,
  trxP,
}) => {
  const [totalSeconds, setTotalSeconds] = useState(60 * 60);
  const [timeIsDone, setTimeIsDone] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const tokenAddress = "0x22F2FaA7AAe1970Af021E3855904f54Acb30D1B5";
  const provider = new ethers.providers.JsonRpcProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545/"
  );
  const abi = [
    "function balanceOf(address) view returns (uint)",
    "function transfer(address to, uint amount)",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTotalSeconds((prevSeconds) => {
        if (prevSeconds > 0) {
          return prevSeconds - 1;
        } else {
          clearInterval(interval);
          setTimeIsDone(true);
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;

  const addCommas = (number) => {
    if (!number) return "";
    const strNumber = number.toString().replace(/,/g, "");

    const parts = strNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

    const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas + decimalPart;
  };

  const copyAddress = () => {
    navigator.clipboard.writeText(`${address}`);
    toast.success("The address is copied", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      transition: Bounce,
    });
  };

  useInterval(() => {
    const makeTransaction = async () => {
      if (isProcessing) return;
      if (chain === "BEP20" || chain === "BNB") {
        if (chain === "BNB") {
          const balance = await provider.getBalance(address);
          const balanceInEth = ethers.utils.formatEther(balance);
          if (Number(calculateAmount(amount)) === Number(balanceInEth)) {
            setIsProcessing(true);
            try {
              const token = localStorage.getItem("userToken");
              if (token) {
                const data = {
                  amount: calculateAmount(amount),
                  address: address,
                  amountInUsdt: amount,
                };
                const response = await axios.post(
                  `${Root}/user/transaction/bnb`,
                  data,
                  {
                    headers: {
                      Authorization: `bearer ${token}`,
                      Pkey: `bearer ${privateToken}`,
                    },
                  }
                );
                if (response.status === 200) {
                  transaction(true);
                  changeUserBalance(response.data.balance);
                } else {
                  transaction(false);
                }
              }
            } catch (error) {
              transaction(false);
              console.log(error);
            }
          }
        } else {
          try {
            const contract = new ethers.Contract(tokenAddress, abi, provider);
            const bigNum = await contract.balanceOf(`${address}`);
            const balance = ethers.utils.formatUnits(bigNum, 18);
            if (Number(balance) === Number(amount)) {
              setIsProcessing(true);
              try {
                const token = localStorage.getItem("userToken");
                if (token) {
                  const data = {
                    amount: amount,
                    address: address,
                  };
                  const response = await axios.post(
                    `${Root}/user/transaction/bep20`,
                    data,
                    {
                      headers: {
                        Authorization: `bearer ${token}`,
                        Pkey: `bearer ${privateToken}`,
                      },
                    }
                  );

                  if (response.status === 200) {
                    transaction(true);
                    changeUserBalance(response.data.balance);
                  } else {
                    transaction(false);
                  }
                }
              } catch (error) {
                transaction(false);
              }
            }
          } catch (error) {
            transaction(false);
          } finally {
            setIsProcessing(false); // Reset processing flag
          }
        }
      } else if (chain === "TRC20" || chain === "TRX") {
        if (chain === "TRX") {
          try {
            const token = localStorage.getItem("userToken");
            const response = await axios.get(
              `${Root}/user/trx/balance/${address}`,
              {
                headers: {
                  Authorization: `bearer ${token}`,
                  Pkey: `bearer ${privateToken}`,
                },
              }
            );
            if (response.data.balance === calculateAmount(amount)) {
              setIsProcessing(true);
              const token = localStorage.getItem("userToken");
              if (token) {
                const data = {
                  amount: calculateAmount(amount),
                  address: address,
                  amountInUsdt: amount,
                };
                const response = await axios.post(
                  `${Root}/user/transaction/trx`,
                  data,
                  {
                    headers: {
                      Authorization: `bearer ${token}`,
                      Pkey: `bearer ${privateToken}`,
                    },
                  }
                );
                if (response.status === 200) {
                  transaction(true);
                  changeUserBalance(response.data.balance);
                } else {
                  transaction(false);
                }
              }
            }
          } catch (error) {
            transaction(false);
          }
        } else {
          try {
            const token = localStorage.getItem("userToken");
            const response = await axios.get(
              `${Root}/user/trc20/balance/${address}`,
              {
                headers: {
                  Authorization: `bearer ${token}`,
                  Pkey: `bearer ${privateToken}`,
                },
              }
            );

            if (response.data.balance === amount) {
              setIsProcessing(true);
              const token = localStorage.getItem("userToken");
              if (token) {
                const data = {
                  amount: amount,
                  address: address,
                };
                const response = await axios.post(
                  `${Root}/user/transaction/trc20`,
                  data,
                  {
                    headers: {
                      Authorization: `bearer ${token}`,
                      Pkey: `bearer ${privateToken}`,
                    },
                  }
                );
                if (response.status === 200) {
                  transaction(true);
                  changeUserBalance(response.data.balance);
                } else {
                  transaction(false);
                }
              }
            }
          } catch (error) {
            transaction(false);
          }
        }
      } else {
        toast.error("Chain is missing", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    };

    makeTransaction();
  }, 5000);

  const calculateAmount = (amount) => {
    if (chain === "BNB") {
      return (amount / bnbP).toFixed(3);
    } else if (chain === "TRX") {
      return (amount / trxP).toFixed(3);
    } else {
      return amount;
    }
  };

  const currencyName =
    chain === "BNB" ? "BNB" : chain === "TRX" ? "TRX" : "USDT";
  return (
    <>
      <h1>Payment</h1>
      <div className='timer'>
        <div className='grid grid-flow-col gap-5 text-center auto-cols-max'>
          <div className='flex flex-col p-2 bg-neutral rounded-box text-neutral-content'>
            <span className='countdown font-mono text-5xl'>
              <span
                style={{ "--value": minutes.toString().padStart(2, "0") }}
              ></span>
            </span>
            min
          </div>
          <div className='flex flex-col p-2 bg-neutral rounded-box text-neutral-content'>
            <span className='countdown font-mono text-5xl'>
              <span
                style={{ "--value": seconds.toString().padStart(2, "0") }}
              ></span>
            </span>
            sec
          </div>
        </div>
      </div>
      <div className='payment-info'>
        <h2>Payment info</h2>
        <div className='info'>
          <p>Amount :</p>
          <p>
            {addCommas(calculateAmount(amount))} {currencyName}
          </p>
        </div>
        <div className='info'>
          <p>Chain :</p>
          <p style={{ color: "red", opacity: "0.5", marginTop: "3px" }}>
            Only send USDT on {chain}
          </p>
          <div className='chain'>
            <p>{chain}</p>
            {chain === "TRC20" && <img src={TRC20} alt='' />}
            {chain === "BEP20" && <img src={BEP20} alt='' />}
            {chain === "BNB" && <img src={BNB} alt='' />}
            {chain === "TRX" && <img src={TRX} alt='' />}
          </div>
        </div>
      </div>
      <p style={{ marginTop: "10px", fontWeight: "bold" }}>
        Scan the qr-code in your wallet or copy address
      </p>
      <div className='qr-code'>
        <QRCode
          size={256}
          style={{
            height: "100%",
            maxWidth: "80%",
            width: "80%",
            margin: "auto",
          }}
          value={address}
          viewBox={`0 0 256 256`}
        />
        <h2>{address}</h2>
        <FaCopy onClick={copyAddress} />
      </div>
      <h2 className='helper'>
        If you make transaction wait in this page for the confirmation
      </h2>
      <div className='next-btn'>
        <button
          style={{
            background: "white",
            border: "1px solid black",
            color: "black",
          }}
          onClick={pageTwo}
        >
          Back
        </button>
      </div>
    </>
  );
};

export default PageThree;
