import { useState } from "react";
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Login from "./components/Login/Login";
import Main from "./components/Dashboard/Main";
import { ToastContainer, Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClikerMain from "./components/cliker/ClikerMain";
import Tap from "./components/cliker/Tap";
import Home from "./components/cliker/Home";
import Task from "./components/cliker/Task";
import Mine from "./components/cliker/Mine";
import Refferal from "./components/cliker/Referral";

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const [canAccessMiniApp, setCanAccessMiniApp] = useState(false);
  const [userBalance, setUserBalance] = useState();
  const [userId, setUserId] = useState(null);
  // const Root = "http://localhost:5000";
  // const clickerRoot = "http://localhost:5000/clicker";
  const clickerRoot = "/api/clicker";
  const Root = "/api";
  const login = (balance) => {
    setIsLogin(true);
    setUserBalance(balance);
  };
  const changeUserBalance = (balance) => {
    setUserBalance(balance);
  };
  const logOut = () => {
    setIsLogin(false);
    localStorage.removeItem("userToken");
  };

  return (
    <div className='App'>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='dark'
      />
      <Routes>
        {isLogin ? (
          <Route
            path='/'
            element={
              <Main
                Root={Root}
                logOut={logOut}
                userBalance={userBalance}
                changeUserBalance={changeUserBalance}
                isPhone={canAccessMiniApp}
              />
            }
          />
        ) : (
          <>
            <Route
              path='/'
              element={
                <Login Root={Root} isLogin={login} isPhone={canAccessMiniApp} />
              }
            />
          </>
        )}

        <Route
          path='/clicker/*'
          element={<ClikerMain Root={clickerRoot} setUserId={setUserId} setCanAccessMiniApp={setCanAccessMiniApp}/>}
        >
          <Route path='' element={<Home />} />
          <Route
            path='tap'
            element={<Tap userId={userId} Root={clickerRoot} />}
          />
          <Route
            path='task'
            element={<Task userId={userId} Root={clickerRoot} />}
          />
          <Route
            path='mine'
            element={<Mine userId={userId} Root={clickerRoot} />}
          />
          <Route
            path='ref'
            element={<Refferal userId={userId} Root={clickerRoot} />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
//scp -r build/* root@31.14.40.86:/var/www/app.red-dog.org/my-app/build
