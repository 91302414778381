const Tap = () => {
  return (
    <>
      <div className='tap'>
        <h1 style={{ margin: "auto", marginTop: "250px", fontSize: "2rem" }}>
          Coming soon...{" "}
        </h1>
      </div>
    </>
  );
};

export default Tap;
