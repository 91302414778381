import React, { useState, useEffect, useRef } from "react";
import { IoIosHome } from "react-icons/io";
import { FaTasks } from "react-icons/fa";
import { PiHandTap } from "react-icons/pi";
import { GiTwoCoins } from "react-icons/gi";
import { FaUserFriends } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";

const Menu = () => {
  const [menuPosition, setMenuPosition] = useState(126);
  const menuIndicatorRef = useRef(null);
  const menuBarRef = useRef(null);
  const location = useLocation();
  const [currentIndex, setCurrentIndex] = useState(location.pathname);

  const handleMenuClick = (index, offsetLeft) => {
    setMenuPosition(offsetLeft);
    setCurrentIndex(index);
  };
  const menu = [
    {
      icon: <PiHandTap />,
      label: "HandTap",
      text: "Tap",
      link: "/clicker/tap",
      offset: 20,
    },
    {
      icon: <FaTasks />,
      label: "Tasks",
      text: "Tasks",
      link: "/clicker/task",
      offset: 74,
    },
    {
      icon: <IoIosHome />,
      label: "Home",
      text: "Home",
      link: "/clicker",
      offset: 126,
    },
    {
      icon: <GiTwoCoins />,
      label: "Coins",
      text: "Mine",
      link: "/clicker/mine",
      offset: 184,
    },
    {
      icon: <FaUserFriends />,
      label: "Friends",
      text: "Refferal",
      link: "/clicker/ref",
      offset: 237,
    },
    { icon: <IoIosHome />, label: "Presale", text: "Presale", link: "/" },
  ];

  useEffect(() => {
    const currentMenuItem = menu.find(
      (item) => item.link === location.pathname
    );
    if (currentMenuItem) {
      setMenuPosition(currentMenuItem.offset);
      setCurrentIndex(currentMenuItem.link);
    }
  }, [location.pathname, menu]);

  useEffect(() => {
    if (menuIndicatorRef.current && menuBarRef.current) {
      menuIndicatorRef.current.style.left = `${menuPosition}px`;
      menuBarRef.current.style.backgroundPosition = `${menuPosition - 8}px`;
    }
  }, [menuPosition]);

  return (
    <div className='menu'>
      <div className='sc-bottom-bar' ref={menuBarRef}>
        <div className='sc-nav-indicator' ref={menuIndicatorRef}></div>
        {menu.map((item, index) => (
          <Link
            key={index}
            className={`sc-menu-item ${
              location.pathname === item.link ? "sc-current" : ""
            }`}
            to={item.link}
            onClick={(e) => {
              handleMenuClick(item.link, item.offset);
            }}
          >
            {item.icon}
            <p>{item.text}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Menu;
