const Mine = () => {
  return (
    <>
      <div
        className='mine'
        style={{ margin: "auto", marginTop: "250px", fontSize: "2rem" }}
      >
        <h1>Coming soon... </h1>
      </div>
    </>
  );
};

export default Mine;
