const Home = () => {
  return (
    <>
      <div className='home'>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Doloribus
          nam voluptatibus quod velit, id voluptatem quae harum architecto
          molestiae enim iste placeat ad ut hic blanditiis consequatur. Cumque,
          sequi voluptatem dolorum illo placeat odit debitis possimus illum
          voluptate asperiores? Sed natus distinctio veniam autem obcaecati
          libero adipisci laborum fugiat consequuntur.
        </p>
      </div>
    </>
  );
};

export default Home;
