import Header from "../Header";
import BuyForm from "./BuyFrom";

const Main = ({ logOut, Root, userBalance, changeUserBalance, isPhone }) => {
  const backToClicker = () => {
    window.location.href = "https://app.red-dog.org/clicker";
  };
  return (
    <>
      <div className='main'>
        {isPhone && (
          <button className='back-ck-btn-2' onClick={backToClicker}>
            Back to clicker
          </button>
        )}
        <Header logOut={logOut} userBalance={userBalance} />
        <BuyForm Root={Root} changeUserBalance={changeUserBalance} />
      </div>
    </>
  );
};

export default Main;
