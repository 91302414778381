import inv from "../../assets/clicker/inviteOne.png";
import User from "../../assets/clicker/User.png";
import { FaCopy } from "react-icons/fa6";
import axios from "axios";
import { useEffect, useState } from "react";
import { message } from "@vavt/message";

const Refferal = ({ Root }) => {
  const [data, setData] = useState(null);
  const userId = localStorage.getItem("id");
  console.log(localStorage);
  const botUrl = "https://t.me/testisphoneuser_bot";

  const copyLink = () => {
    const refLink = `${botUrl}/test?startapp=${userId}`;
    navigator.vibrate([20]);
    navigator.clipboard.writeText(refLink);
    message.success("Link copied successfully", { class: "t-info" });
  };

  const shareRefInTg = () => {
    const refLink = `${botUrl}/test?startapp=${userId}`;
    const tgUrl = `https://t.me/share/url?url=${refLink}`;
    window.location.href = tgUrl;
  };
  const getData = async () => {
    try {
      const response = await axios.get(`${Root}/user/${userId}`);
      setData(response.data.refDetails);
    } catch (error) {
      message.error("Error in fecthing Data", { class: "t-info" });
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className='refferal'>
        <div className='ref-head'>
          <h1>Invite friends</h1>
          <p>Invaite your friends and get bonuses</p>
        </div>
        <div className='ref-rewards'>
          <img src={inv} alt='' />
          <img src={inv} alt='' />
        </div>
        <div className='share-ref'>
          <button className='share-ref-btn' onClick={shareRefInTg}>
            Invite friend
          </button>
          <button className='copy-ref-btn' onClick={copyLink}>
            <FaCopy />
          </button>
        </div>
        <div className='line'></div>
        <div className='friend-list'>
          <h1>Friend list</h1>
          {data &&
            data.map((item, index) => (
              <div className='f-list' key={index}>
                <img src={User} alt='' />
                <p>{item.userName}</p>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Refferal;
