
import { IoSwapHorizontalOutline } from "react-icons/io5";

const PageOne = ({ handleChange, pageTwo, amountRecive, amountToBuy }) => {
  const addCommas = (number) => {
    if (!number) return "";
    const strNumber = number.toString().replace(/,/g, "");

    const parts = strNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

    const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas + decimalPart;
  };
  const handleInput = (e) => {
    const value = e.target.value;
    const onlyNums = value.replace(/[^0-9.]/g, "");
    e.target.value = onlyNums;
    handleChange(e);
  };
 
  return (
    <>
      <h1>Presale</h1>
      <div className='input-container'>
        <input
          type='text'
          placeholder='USDT'
          className='input input-bordered w-full max-w-xs'
          onInput={handleInput}
          min={0}
          value={addCommas(amountToBuy)}
        />
        <span className='currency-label'>USDT</span>
      </div>
      <button disabled className='swap-btn'>
        <IoSwapHorizontalOutline />
      </button>
      <div className='input-container'>
        <input
          type='text'
          placeholder='You will recive'
          className='input input-bordered w-full max-w-xs'
          disabled
          value={`${addCommas(amountRecive) || "You will recive"}`}
        />
        <span className='currency-label'>TOKEN</span>
      </div>
      <button className='pay-btn' onClick={pageTwo}>
        Pay
      </button>

    </>
  );
};

export default PageOne;
