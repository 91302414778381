import { useEffect, useState } from "react";

import { Bounce, toast } from "react-toastify";

import PageOne from "./formData/PageOne";
import axios from "axios";
import PageTwo from "./formData/PageTwo";
import PageThree from "./formData/PageThree";
import PageFour from "./formData/PageFour";

const BuyForm = ({ Root, changeUserBalance }) => {
  const [amountToBuy, setAmountToBuy] = useState(0);
  const [BNBPrice, setBNBPrice] = useState(0);
  const [TRXPrice, setTRXPRice] = useState(0);
  const [amountRecive, setAmountRecive] = useState(0);
  const [privateToken, setPrivateToken] = useState();
  const [page, setPage] = useState("pageOne");
  const [chain, setChain] = useState("");
  const [addressToPay, setAddressToPay] = useState();
  const [isPayed, setIsPayed] = useState(false);
  const amountChanger = (amount) => {
    setAmountToBuy(amount);
    setAmountRecive(amount * 1000);
  };
  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "" || parseFloat(value) >= 0) {
      amountChanger(value);
    } else {
      amountChanger("0");
      e.target.value = "0";
    }
  };
  const pageTwo = () => {
    if (amountToBuy > 0 && amountToBuy) {
      setPage("pageTwo");
    } else {
      toast.error("The amount can not be 0", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }
  };
  const pageOne = () => {
    setPage("pageOne");
  };

  const selectChain = (Schain) => {
    if (Schain === chain) {
      setChain("");
    } else {
      setChain(Schain);
    }
  };

  const getQrCode = async () => {
    if (chain === "BEP20" || chain === "BNB") {
      if (amountToBuy > 0) {
        try {
          const token = localStorage.getItem("userToken");
          const data = {
            chain,
          };
          if (token) {
            const response = await axios.post(`${Root}/user/pay`, data, {
              headers: { Authorization: `bearer ${token}` },
            });
            setAddressToPay(response.data.address);
            setPrivateToken(response.data.token);
            if (chain === "BNB") {
              const bnbPrice = await axios.get(
                "https://api.diadata.org/v1/assetQuotation/BinanceSmartChain/0x0000000000000000000000000000000000000000"
              );
              setBNBPrice(bnbPrice.data.Price);
            }
            setPage("pageThree");
          } else {
            toast.error(`Please login again`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Bounce,
            });
          }
        } catch (error) {
          toast.error(`${error.response.data.message}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            transition: Bounce,
          });
        }
      } else {
        toast.error("The amount can not be 0", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    } else if (chain === "TRC20" || chain === "TRX") {
      try {
        if (amountToBuy > 0) {
          const token = localStorage.getItem("userToken");
          const data = {
            chain,
          };
          if (token) {
            const response = await axios.post(`${Root}/user/pay`, data, {
              headers: { Authorization: `bearer ${token}` },
            });
            setAddressToPay(response.data.address);
            setPrivateToken(response.data.token);
            if (chain === "TRX") {
              const trxPrice = await axios.get(
                "https://api.diadata.org/v1/assetQuotation/Tron/0x0000000000000000000000000000000000000000"
              );
              setTRXPRice(trxPrice.data.Price);
            }
            setPage("pageThree");
          } else {
            toast.error(`Please login again`, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
              transition: Bounce,
            });
          }
        }
      } catch (error) {
        console.log(error);
        toast.error(`${error.response.data.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
        });
      }
    } else {
      toast.error("Select the chain", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
    }
  };

  const transaction = (isDone) => {
    setIsPayed(isDone);
    setPage("pageFour");
  };

  return (
    <>
      <div className='buy-form'>
        {page === "pageOne" && (
          <PageOne
            pageTwo={pageTwo}
            handleChange={handleChange}
            amountRecive={amountRecive}
            amountToBuy={amountToBuy}
          />
        )}
        {page === "pageTwo" && (
          <PageTwo
            getQrCode={getQrCode}
            pageOne={pageOne}
            selectChain={selectChain}
            chain={chain}
          />
        )}
        {page === "pageThree" && (
          <PageThree
            address={addressToPay}
            amount={amountToBuy}
            pageTwo={pageTwo}
            transaction={transaction}
            Root={Root}
            changeUserBalance={changeUserBalance}
            privateToken={privateToken}
            chain={chain}
            bnbP={BNBPrice}
            trxP={TRXPrice}
          />
        )}
        {page === "pageFour" && (
          <PageFour
            isPayed={isPayed}
            amountRecive={amountRecive}
            pageOne={pageOne}
          />
        )}
      </div>
    </>
  );
};

export default BuyForm;

//Join the secure presale without needing to connect your wallet
