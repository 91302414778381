import coin from "../../assets/clicker/redcoin.png";
import Daily from "../../assets/clicker/Daily.png";
import telegram from "../../assets/clicker/telegram.png";
import youtube from "../../assets/clicker/youtube.png";
import instagram from "../../assets/clicker/instagram.png";
import { message } from "@vavt/message";
import axios from "axios";
import { FaChevronRight } from "react-icons/fa";
import { useState } from "react";

const Task = ({ Root }) => {
  const [tgMember, setTgMemeber] = useState(false);
  const [youtubeVisited, setYoutubeVisited] = useState(false);
  const [instagramVisited, setInstagramVisited] = useState(false);

  const joinTgChannel = async () => {
    const channelUrl = "https://t.me/nexpop";
    window.location.href = channelUrl;
    setTimeout(() => {
      claimTgReward();
    }, 2000);
  };

  const claimTgReward = async () => {
    try {
      const userId = localStorage.getItem("id");
      const response = await axios.post(`${Root}/task/tg`, { userId });
      if (response.status === 200) {
        message.success("Task completed", { class: "t-info" });
        setTgMemeber(true);
      }
    } catch (error) {
      console.log(error);
      message.warning(`${error.response.data.message}`, { class: "t-info" });
    }
  };

  const handleYoutubeClick = () => {
    window.open("https://www.youtube.com/your-channel", "_blank");
    setTimeout(() => {
      setYoutubeVisited(true);
    }, 2000);
  };

  const handleInstagramClick = () => {
    window.open("https://www.instagram.com/your-profile", "_blank");
    setTimeout(() => {
      setInstagramVisited(true);
    }, 2000);
  };

  const claimYoutubeReward = async () => {
    try {
      await axios.post(`${Root}/task`);
    } catch (error) {
      // Handle error
    }
  };

  const claimInstagramReward = async () => {
    try {
      await axios.post(`${Root}/task`);
    } catch (error) {
      // Handle error
    }
  };

  return (
    <>
      <div className='task'>
        <img src={coin} alt='' />
        <div className='task-head'>
          <h1>Complete the tasks for get more bonuses</h1>
        </div>
        <div className='task-to-do'>
          <div className='daily-task'>
            <h1>Daily reward</h1>
            <div className='daily-reward-btn'>
              <img src={Daily} alt='' />
              <h1>Claim daily reward</h1>
            </div>
          </div>
          <div className='social-task'>
            <h1>One time task</h1>
            <div className='one-time-tasks'>
              <div className='tasks' onClick={joinTgChannel}>
                <img src={telegram} alt='' />
                <h1>Join our channel</h1>
                <p>+ 15,000</p>
                {tgMember ? (
                  <div className='t-claim'>
                    <span>Claim</span>
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </div>
              <div className='tasks' onClick={handleYoutubeClick}>
                <img src={youtube} alt='' />
                <h1>Subscribe Youtube</h1>
                <p>+ 20,000</p>
                {youtubeVisited ? (
                  <div className='t-claim' onClick={claimYoutubeReward}>
                    <span>Claim</span>
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </div>
              <div className='tasks' onClick={handleInstagramClick}>
                <img src={instagram} alt='' />
                <h1>Follow us in intagram</h1>
                <p>+ 5,000</p>
                {instagramVisited ? (
                  <div className='t-claim' onClick={claimInstagramReward}>
                    <span>Claim</span>
                  </div>
                ) : (
                  <FaChevronRight />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='task-finished'></div>
      </div>
    </>
  );
};

export default Task;
