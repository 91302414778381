import { useState } from "react";
import BEP20 from "../../../assets/BSCLogo.png";
import TRC20 from "../../../assets/TRONLogo.png";
import BNB from "../../../assets/BNB.png";
import TRX from "../../../assets/TRX.png";
import USDT from "../../../assets/USDT.png";

const PageTwo = ({ getQrCode, pageOne, chain, selectChain }) => {
  return (
    <>
      <h1>Select the chain</h1>
      <div className='select-chain'>
        <div
          className={`chain ${chain === "BEP20" ? "active" : ""}`}
          id='chain'
          onClick={() => {
            selectChain("BEP20");
          }}
        >
          <img src={USDT} alt='' />
          <h3>
            USDT ON{" "}
            <span style={{ color: "rgb(255, 196, 0)", fontSize: "1.1rem" }}>
              BEP20
            </span>
          </h3>
        </div>
        <div
          className={`chain  ${chain === "TRC20" ? "active" : ""}`}
          onClick={() => {
            selectChain("TRC20");
          }}
        >
          <img src={USDT} alt='' />
          <h3>
            USDT ON{" "}
            <span style={{ color: "red", fontSize: "1.1rem" }}>TRC20</span>
          </h3>
        </div>
      </div>
      <div className='select-chain'>
        <div
          className={`chain  ${chain === "TRX" ? "active" : ""}`}
          onClick={() => {
            selectChain("TRX");
          }}
        >
          <img src={TRX} alt='' style={{ width: "45px", height: "45px" }} />
          <h3>
            PAY WITH{" "}
            <span style={{ color: "red", fontSize: "1.1rem" }}>TRX</span>
          </h3>
        </div>
        <div
          className={`chain ${chain === "BNB" ? "active" : ""}`}
          id='chain'
          onClick={() => {
            selectChain("BNB");
          }}
        >
          <img src={BNB} alt='' style={{ width: "45px", height: "45px" }} />
          <h3>
            PAY WITH{" "}
            <span style={{ color: "rgb(255, 196, 0)", fontSize: "1.1rem" }}>
              BNB
            </span>
          </h3>
        </div>
      </div>
      <div className='next-btn'>
        <button
          style={{
            background: "white",
            border: "1px solid black",
            color: "black",
          }}
          onClick={pageOne}
        >
          Back
        </button>
        <button onClick={getQrCode}>Next</button>
      </div>
    </>
  );
};

export default PageTwo;
