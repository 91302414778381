const PageFour = ({ isPayed, amountRecive, pageOne }) => {
  const addCommas = (number) => {
    if (!number) return "";
    const strNumber = number.toString().replace(/,/g, "");

    const parts = strNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

    const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas + decimalPart;
  };

  return (
    <>
      <div className='paymnet-final'>
        {isPayed && isPayed ? (
          <>
            <div className='transaction-success'>
              <h2>
                The transaction was completed successfully and you can withdraw
                <span> {addCommas(amountRecive)}</span> tokens on the due date
              </h2>
            </div>
          </>
        ) : (
          <>
            <div className='transaction-fail'>
              <h2>
                Your transaction failed, if you have deposited the correct
                amount at the specified time and encountered this message,
                please send a message to the Support.
              </h2>
            </div>
          </>
        )}
        <button onClick={pageOne}>Ok</button>
      </div>
    </>
  );
};

export default PageFour;
