const Header = ({ logOut, userBalance }) => {
  const addCommas = (number) => {
    if (!number) return "";
    const strNumber = number.toString().replace(/,/g, "");

    const parts = strNumber.split(".");
    const integerPart = parts[0];
    const decimalPart = parts.length > 1 ? `.${parts[1]}` : "";

    const numberWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return numberWithCommas + decimalPart;
  };
  return (
    <>
      <div className='navbar bg-neutral bg-base-100'>
        <div className='navbar-start'>
          <div className='dropdown'>
            <div tabIndex={0} role='button' className='btn btn-ghost lg:hidden'>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-5 w-5'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h8m-8 6h16'
                />
              </svg>
            </div>
            <ul
              tabIndex={0}
              className='menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow'
            >
              <li>
                <a className='presale-nav-btn' style={{ color: "black" }}>
                  Presale
                </a>
              </li>
              <li>
                <a style={{ cursor: "none", color: "rgb(158, 158, 158)" }}>
                  Claim
                </a>
              </li>
            </ul>
          </div>
          <a className='btn btn-ghost text-xl'>
            $Toekn balance = {addCommas(userBalance) || 0}
          </a>
        </div>
        <div className='navbar-center hidden lg:flex'>
          <ul className='menu menu-horizontal px-1'>
            <li>
              <a className='presale-nav-btn'>Presale</a>
            </li>
            <li>
              <a
                style={{ cursor: "none", color: "rgb(158, 158, 158)" }}
                title='Coming soon'
              >
                Claim
              </a>
            </li>
          </ul>
        </div>
        <div className='navbar-end'>
          <button className='btn' onClick={logOut}>
            Log out
          </button>
        </div>
      </div>
    </>
  );
};
export default Header;
